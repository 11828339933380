@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  opacity: 0.8;
  background-image: linear-gradient(#f8f8f8 1px, transparent 1px),
    linear-gradient(to right, #f8f8f8 1px, #ffffff 1px);
  background-size: 60px 60px;
}
